<template>
    <div>
        <ts-page-title
            :title="$t('driverCompensation.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('driverCompensation.pageTitle'),
                    href: '/admin/finance/driver-compensations'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-loading-banner :loading="loading">
            <ts-panel>
                <ts-panel-wrapper>
                    <div class="tw-flex tw-space-x-3">
                        <div class="tw-flex tw-items-center">
                            <label class="required">{{
                                $t("driverCompensation.date")
                            }}</label>
                            <div class="tw-flex-none tw-ml-3">
                                <a-range-picker
                                    v-model="model.dateRange"
                                    :allowClear="false"
                                    format="DD-MM-YYYY"
                                    valueFormat="DD-MM-YYYY"
                                    style="width: 200px"
                                    @change="onDriverChange"
                                />
                                <div
                                    class="tw-text-red-500"
                                    v-if="errors.has('transaction_date')"
                                >
                                    {{ errors.first("transaction_date") }}
                                </div>
                            </div>
                        </div>
                        <div class="tw-flex tw-items-center">
                            <label class="required">{{
                                $t("driverCompensation.driverName")
                            }}</label>
                            <div class="tw-flex-none tw-ml-3">
                                <a-select
                                    show-search
                                    v-model="model.driver_id"
                                    style="width: 300px"
                                    :default-active-first-option="false"
                                    :show-arrow="false"
                                    :filter-option="false"
                                    :not-found-content="null"
                                    :placeholder="
                                        $t('driverCompensation.typeToSearch')
                                    "
                                    :loading="fetch_driver"
                                    @search="handleDriverSearch"
                                    @change="onDriverChange"
                                >
                                    <a-select-option
                                        v-for="c in driverList"
                                        :key="c.driver_id"
                                    >
                                        {{ c.driver_name }}
                                    </a-select-option>
                                </a-select>
                                <div
                                    class="tw-text-red-500"
                                    v-if="errors.has('driver_id')"
                                >
                                    {{ errors.first("driver_id") }}
                                </div>
                            </div>
                        </div>
                        <ts-button color="primary" @click.prevent="onSave">{{
                            $t("save")
                        }}</ts-button>
                        <ts-button
                            class="mr-2"
                            @click.prevent="
                                () =>
                                    $router.push(
                                        '/admin/finance/driver-compensations'
                                    )
                            "
                        >
                            {{ $t("cancel") }}
                        </ts-button>
                    </div>
                    <br />

                    <div class="tw-w-full">
                        <table class="tw-w-full">
                            <thead>
                                <tr class="tw-bg-blue-400 tw-text-white">
                                    <th
                                        class="tw-px-2 tw-py-1 tw-border tw-uppercase tw-whitespace-nowrap"
                                    >
                                        {{ $t("no") }}
                                    </th>
                                    <th
                                        class="tw-px-2 tw-py-1 tw-border tw-uppercase tw-whitespace-nowrap"
                                    >
                                        {{
                                            $t(
                                                "driverCompensation.deliveryNumber"
                                            )
                                        }}
                                    </th>
                                    <th
                                        class="tw-px-2 tw-py-1 tw-border tw-uppercase tw-whitespace-nowrap"
                                    >
                                        {{
                                            $t(
                                                "driverCompensation.transactionDate"
                                            )
                                        }}
                                    </th>
                                    <th
                                        class="
                      tw-px-2
                      tw-py-1
                      tw-border tw-uppercase
                      tw-text-center
                      tw-whitespace-nowrap
                    "
                                    >
                                        {{
                                            $t("driverCompensation.driverCode")
                                        }}
                                    </th>
                                    <th
                                        class="
                      tw-px-2
                      tw-py-1
                      tw-border tw-uppercase
                      tw-text-center
                      tw-whitespace-nowrap
                    "
                                        style="width: 200px"
                                    >
                                        {{
                                            $t("driverCompensation.driverName")
                                        }}
                                    </th>
                                    <th
                                        class="
                      tw-px-2
                      tw-py-1
                      tw-border tw-uppercase
                      tw-text-center
                      tw-whitespace-nowrap
                    "
                                        style="width: 200px"
                                    >
                                        {{ $t("driverCompensation.shopName") }}
                                    </th>
                                    <th
                                        class="tw-px-2tw-py-1tw-border tw-uppercase tw-text-centertw-whitespace-nowrap"
                                        style="width: 200px"
                                    >
                                        {{
                                            $t(
                                                "driverCompensation.receiverPhone"
                                            )
                                        }}
                                    </th>
                                    <th
                                        class="tw-px-2tw-py-1tw-border tw-uppercase tw-text-centertw-whitespace-nowrap"
                                        style="width: 200px"
                                    >
                                        {{
                                            $t(
                                                "driverCompensation.receiverLocation"
                                            )
                                        }}
                                    </th>
                                    <th
                                        class="
                      tw-px-2
                      tw-py-1
                      tw-border tw-uppercase
                      tw-text-center
                      tw-whitespace-nowrap
                    "
                                        style="width: 150px"
                                    >
                                        {{
                                            $t("driverCompensation.serviceType")
                                        }}
                                    </th>
                                    <th
                                        class="
                      tw-px-2
                      tw-py-1
                      tw-border tw-uppercase
                      tw-text-center
                      tw-whitespace-nowrap
                    "
                                        style="width: 150px"
                                    >
                                        {{ $t("driverCompensation.pickupFee") }}
                                    </th>
                                    <th
                                        class="
                      tw-px-2
                      tw-py-1
                      tw-border tw-uppercase
                      tw-text-center
                      tw-whitespace-nowrap
                    "
                                        style="width: 150px"
                                    >
                                        {{ $t("driverCompensation.driverFee") }}
                                    </th>
                                    <th
                                        class="
                      tw-px-2
                      tw-py-1
                      tw-border tw-uppercase
                      tw-text-center
                      tw-whitespace-nowrap
                    "
                                        style="width: 150px"
                                    >
                                        {{
                                            $t(
                                                "driverCompensation.amountTobeClear"
                                            )
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(p, index) in drivers" :key="index">
                                    <td
                                        class="tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                      tw-text-center"
                                    >
                                        {{ index + 1 }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                      tw-text-center
                    "
                                    >
                                        {{ p.delivery_number }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        {{ p.transaction_date }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        {{ p.driver_code }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        {{ p.driver_name }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        {{ p.shop_name }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        {{ p.receiver_phone }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        {{ p.receiver_location }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        {{ p.service_type }}
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        <ts-accounting-format
                                            currency="KHR"
                                            :digit="0"
                                            :value="p.pickup_fee"
                                        />
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="p.driver_fee"
                                        />
                                    </td>
                                    <td
                                        class="
                      tw-whitespace-nowrap
                      tw-px-2
                      tw-py-1
                      tw-border
                      tw-uppercase
                    "
                                    >
                                        <ts-accounting-format
                                            :digit="2"
                                            :value="p.amount_tobe_clear"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td
                                        colspan="9"
                                        class="tw-py-1 tw-px-2 tw-text-right"
                                    >
                                        <h5
                                            class="tw-font-semibold tw-uppercase"
                                        >
                                            {{ $t("driverCompensation.total") }}
                                        </h5>
                                    </td>
                                    <td class="tw-py-1 tw-px-2">
                                        <ts-accounting-format
                                            class="tw-text-blue-800 tw-font-semibold"
                                            currency="KHR"
                                            :value="
                                                sumBy(drivers, 'pickup_fee')
                                            "
                                        />
                                    </td>
                                    <td class="tw-py-1 tw-px-2">
                                        <ts-accounting-format
                                            class="tw-text-blue-800 tw-font-semibold"
                                            :digit="2"
                                            :value="
                                                sumBy(drivers, 'driver_fee')
                                            "
                                        />
                                    </td>
                                    <td class="tw-py-1 tw-px-2">
                                        <ts-accounting-format
                                            class="tw-text-blue-800 tw-font-semibold"
                                            :digit="2"
                                            :value="
                                                sumBy(
                                                    drivers,
                                                    'amount_tobe_clear'
                                                )
                                            "
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </ts-panel-wrapper>
            </ts-panel>
        </ts-loading-banner>
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import { Errors } from "form-backend-validation";
import { debounce, sumBy } from "lodash";
import { mapActions, mapState } from "vuex";
export default {
    name: "driverCompensationCreate",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            fetch_driver: false,
            preview_pdf: false,
            drivers: [],
            model: {
                dateRange: [
                    moment().format("DD-MM-YYYY"),
                    moment().format("DD-MM-YYYY")
                ],
                driver_id: null
            }
        };
    },
    computed: {
        ...mapState("finance/driverCompensation", ["formModels"]),
        driverList() {
            return this.formModels.driver;
        }
    },
    methods: {
        ...mapActions("finance/driverCompensation", [
            "getFormViewData",
            "detail",
            "store"
        ]),
        sumBy,
        handleDriverSearch: debounce(function(value) {
            this.fetch_driver = true;
            this.getFormViewData({
                params: {
                    fnName: "driver",
                    search_driver: value
                }
            }).finally(() => (this.fetch_driver = false));
        }, 500),
        onDriverChange: debounce(function() {
            if (this.model.driver_id != null) {
                this.loading = true;
                this.drivers = [];
                this.detail({
                    driverId: this.model.driver_id,
                    dateRange: this.model.dateRange
                })
                    .then(response => {
                        this.drivers = response.data;
                    })
                    .catch(err => {
                        this.$notify({ type: "error", text: err.message });
                    })
                    .finally(() => (this.loading = false));
            }
        }, 500),
        onSave() {
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.drivers = [];
            (this.model.dateRange = [
                moment().format("DD-MM-YYYY"),
                moment().format("DD-MM-YYYY")
            ]),
                (this.model.driver_id = null);
        }
    }
};
</script>
